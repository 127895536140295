<template>
  <div class="quote">
    <v-dialog
      v-model="authDialog"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
      max-width="400"
      scrollable
    >
      <v-card class="auth-dialog">
        <v-card-title>
          <v-btn
            color="primary"
            dark
            class="disable-events mr-4"
            fab
            elevation="0"
            x-small
          >
            <v-icon dark> mdi-clipboard-outline </v-icon>
          </v-btn>
          <div class="font-weight-bold">Iniciar cotação</div>
        </v-card-title>
        <v-card-text>
          <div class="logo text-center py-4">
            <img :src="organization.logo" alt="logo" />
          </div>

          <div class="text-center font-weight-bold">
            Informe seu CNPJ e Senha.
          </div>

          <v-form
            @submit.prevent="fetch"
            class="text-center mt-4"
            v-model="authForm.valid"
            ref="form"
          >
            <div style="max-width: 330px; margin: 0 auto">
              <v-text-field
                label="CNPJ"
                autofocus
                dense
                outlined
                name="cnpj"
                v-model="authForm.cnpj"
                :rules="$rules.required"
                v-mask.raw="'##.###.###/####-##'"
              ></v-text-field>
              <v-text-field
                v-model="authForm.pass"
                name="password"
                dense
                label="Senha"
                outlined
                type="password"
              >
              </v-text-field>
              <v-row class="text-center">
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn
                    block
                    type="submit"
                    :loading="authForm.loading"
                    elevation="1"
                    color="primary"
                  >
                    Iniciar
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <div v-if="loading" class="loading-container">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </div> -->
    <div class="main-content" v-if="authenticated">
      <v-row no-gutters>
        <v-col cols="12">
          <div class="text-center text-h6">Cotação de Preços</div>
        </v-col>
      </v-row>

      <v-row class="pa-8 font-weight-medium">
        <v-col>
          <div>ID da cotação: {{ details.quote_id }}</div>
          <div>{{ details.supplier_name }}</div>
        </v-col>
        <v-col>
          <div>Data: 12/12/2023</div>
          <div>E-mail: {{ details.supplier_email }}</div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-form
            ref="form"
            class="box pa-8"
            @submit.prevent="save"
            v-model="form.valid"
            :disabled="disabled"
          >
            <div class="form-content">
              <div class="section-title">Itens</div>
              <v-simple-table class="items-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th class="text-left">Nome</th>
                      <th class="text-left">Marca</th>
                      <th class="text-left">Apresentação</th>
                      <th class="text-left">Quantidade</th>
                      <th class="text-left">Valor Unitário</th>
                      <th class="text-left">Desconto</th>
                      <th class="text-left">Valor total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in form.items"
                      :key="item.id"
                      :class="['pt-4', { par: index % 2 === 0 }]"
                    >
                      <td style="width: 20px">
                        <v-dialog max-width="400px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-badge
                              overlap
                              dot
                              color="error"
                              :value="item.obs != null && item.obs != ''"
                            >
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-comment-processing-outline</v-icon>
                              </v-btn>
                            </v-badge>
                          </template>
                          <v-card>
                            <v-card-title>
                              <v-spacer></v-spacer>
                              <v-btn small icon>
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col>
                                    <v-textarea
                                      name="obs"
                                      label="Obsevação"
                                      v-model="item.obs"
                                      filled
                                      dense
                                    ></v-textarea>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </td>
                      <td>{{ item.name }}</td>
                      <td style="width: 140px">
                        <v-text-field
                          label="Marca"
                          class="mt-6"
                          filled
                          outlined
                          dense
                          background-color="#fff"
                          v-model="item.brand"
                          :rules="$rules.required"
                        ></v-text-field>
                      </td>
                      <td style="width: 140px">
                        <v-text-field
                          label="Apresentação"
                          class="mt-6"
                          disabled
                          filled
                          outlined
                          dense
                          background-color="#fff"
                          v-model="item.presentation"
                          :rules="$rules.required"
                        ></v-text-field>
                      </td>
                      <td style="width: 140px">
                        {{ item.amount }}
                      </td>
                      <td style="width: 140px">
                        <v-money-input
                          v-model="item.value"
                          label="Valor"
                          class="mt-6"
                        ></v-money-input>
                      </td>
                      <td style="width: 140px">
                        <v-money-input
                          v-model="item.discount"
                          label="Desconto"
                          class="mt-6"
                        ></v-money-input>
                      </td>
                      <td class="total">
                        {{ total(item) | money }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="section-title py-8">Valores da cotação</div>
              <v-row>
                <v-col>
                  <v-money-input
                    :value="productsTotal"
                    label="Valor dos podutos"
                    disabled
                  ></v-money-input>
                </v-col>
                <v-icon style="margin-top: -24px">mdi-minus</v-icon>
                <v-col>
                  <v-money-input
                    v-model="form.discount"
                    label="Desconto"
                  ></v-money-input>
                </v-col>
                <v-icon style="margin-top: -24px">mdi-equal</v-icon>
                <v-col>
                  <v-money-input
                    :value="quoteTotal"
                    label="Valor Total"
                    disabled
                  ></v-money-input>
                </v-col>
              </v-row>
              <div class="section-title py-8">Detalhes</div>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Condição de pagamento"
                    filled
                    outlined
                    dense
                    background-color="#fff"
                    v-model="form.payment_method"
                    :rules="[$rules.require]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Validade da cotação"
                    filled
                    outlined
                    dense
                    background-color="#fff"
                    v-model="form.validate"
                    :rules="$rules.date(true)"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Dias para entrega"
                    filled
                    outlined
                    dense
                    background-color="#fff"
                    v-model="form.days_for_delivery"
                    :rules="[$rules.require, $rules.number]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-money-input
                    v-model="form.min"
                    label="Faturamento mínimo"
                  ></v-money-input>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    filled
                    outlined
                    dense
                    background-color="#fff"
                    :items="[
                      { value: 1, text: 'CIF' },
                      { value: 2, text: 'FOB' },
                    ]"
                    v-model="form.shipping_type"
                    :rules="$rules.required"
                    label="Tipo de frete"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    name="obs"
                    label="Observação (opcional)"
                    v-model="form.obs"
                    filled
                    dense
                    background-color="#fff"
                  ></v-textarea>
                </v-col>
              </v-row>
              <div class="form-footer mt-6">
                <v-row>
                  <v-col class="text-center">
                    <v-btn
                      color="primary"
                      type="submit"
                      elevation="0"
                      :loading="saving"
                    >
                      Enviar
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script type="text/javascript">
import Api from '@/resources/NajaSocialApi'
import QuotesEnum from '@/enumerations/quotes_enum'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  name: 'Quote',
  data: () => ({
    loading: false,
    saving: false,
    details: {},
    authenticated: false,
    authDialog: true,
    status: null,
    authForm: {
      cnpj: null,
      pass: null,
      loading: false,
      valid: null,
    },
    form: {
      valid: null,
      discount: 0,
      min: 0,
      items: [],
      deadline: null,
      days_for_delivery: null,
      shipping_type: null,
      obs: null,
      validate: null,
      payment_method: null,
    },
  }),

  computed: {
    ...mapState({
      organization: state => state.data.organization,
    }),

    disabled() {
      return this.status == QuotesEnum.finished
    },

    productsTotal() {
      let sum = 0
      this.form.items.forEach(item => {
        sum = sum + this.total(item)
      })
      return sum
    },

    quoteTotal() {
      return this.productsTotal - this.form.discount
    },
  },

  mounted() {
    // this.$refs.supplierAuth.open()
    // this.fetch()
  },

  methods: {
    _catchResponseError(err, fatalMsg = 'Erro Fatal') {
      if (err.response) {
        if (err?.response?.status != 500) {
          this.$toast.show(err.response.data.errors.join(','), 'error')
        } else {
          this.$toast.show(fatalMsg, 'error')
        }
      } else {
        throw err
      }
    },

    total(item) {
      const amount = item.amount || 0
      const value = item.value || 0
      const discount = item.discount || 0

      return amount * value - discount
    },

    _parse(data) {
      const quote = data.quote
      const supplierQuote = quote.fornecedores[0]
      const supplier = data.supplier

      const items = []

      supplierQuote.produtos.forEach(product => {
        items.push({
          id: product.codigo_produto,
          name: product.nome_produto,
          brand: product.marca,
          amount: product.quantidade,
          value: product.valor_unitario,
          discount: product.valor_desconto,
          obs: product.observacao,
          presentation: product.apresentacao,
          presentation_code: product.codigo_apresentacao,
        })
      })

      this.details = {
        supplier_id: supplier.codigo,
        supplier_name: supplier.nome,
        supplier_email: supplier.email,
        quote_date: quote.data_validade,
        quote_id: quote.codigo,
      }

      this.status = supplierQuote.status_registro_preco

      this.form.items = items
      this.form.deadline = moment(supplierQuote.data_validade).format(
        'DD/MM/YYYY'
      )
      this.form.days_for_delivery = supplierQuote.dias_entrega
      this.form.shipping_type = supplierQuote.tipo_frete
      this.form.obs = null
      this.form.min = supplierQuote.valor_faturamento_minimo
      this.form.payment_method = supplierQuote.condicao_pagamento
      this.form.validate = moment(supplierQuote.data_validade).format(
        'DD/MM/YYYY'
      )
      this.form.obs = quote.observacao
    },

    async save() {
      try {
        const form = this.form
        this.$refs.form.validate()

        if (!form.valid) {
          return
        }

        this.saving = true
        const token = this.$route.params.token

        const deadline = moment(form.deadline, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        )

        const payload = {
          quote: {
            codigo_fornecedor: this.details.supplier_id,
            status_registro_preco: QuotesEnum.finished, // ? [1 - pendente, 2 - finalizado]
            dados_cotacao: {
              data_validade: deadline,
              condicao_pagamento: form.payment_method,
              dias_entrega: parseInt(form.days_for_delivery),
              valor_desconto: form.discount,
              tipo_frete: form.shipping_type,
              valor_faturamento_minimo: form.min,
              produtos: [],
            },
          },
        }

        this.form.items.forEach(item => {
          payload.quote.dados_cotacao.produtos.push({
            codigo_produto: item.id,
            apresentacao: item.presentation,
            codigo_apresentacao: item.presentation_code,
            quantidade: item.amount,
            valor_unitario: item.value,
            valor_desconto: item.discount,
            observacao: item.obs,
            marca: item.brand,
          })
        })

        const saveResponse = await Api.core.quotes.savePrices(token, payload)
        this.$toast.show(saveResponse.data.message, 'success')
      } catch (err) {
        this._catchResponseError(err, 'Erro ao salvar cotação')
      } finally {
        this.saving = false
      }
    },

    async fetch() {
      try {
        this.authForm.loading = true
        const token = this.$route.params.token
        const payload = {
          cnpj: this.authForm.cnpj.replace(/[^\d]+/g, ''),
          pass: this.authForm.pass,
        }

        const quotesResponse = await Api.core.quotes.fetch(token, payload)
        this._parse(quotesResponse.data)

        this.authDialog = false
        this.authenticated = true
      } catch (err) {
        this._catchResponseError(err, 'Erro ao buscar dados')
      } finally {
        this.authForm.loading = false
      }
    },
  },
}
</script>
<style scoped lang="scss">
.auth-dialog {
  .v-card__title {
    font-size: 1rem;
  }
}
.logo img {
  max-height: 40px;
  max-width: 100px;
  width: auto !important;
}
.quote {
  flex-direction: column;
  padding: 1rem;

  .v-data-table {
    background-color: unset;

    th,
    td {
      border: none !important;
      &.total {
        text-align: right;
        max-width: 140px;
        width: 140px;
        overflow-x: auto;
      }
    }
  }

  .par {
    background-color: white;
  }

  .loading-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }

  .text-h6 {
    // font-size: 1.8rem !important;
    font-size: 2.125rem !important;
  }

  .text-subtitle-2 {
    // font-size: 1rem !important;
    font-size: 1.125rem !important;
  }

  .section-title {
    color: var(--v-primary);
    font-weight: bold;
  }
}

.quote-title {
  // height: 100px;
  margin-bottom: 1rem;
}

.quote-body {
  background: #f4f4f8;
  border-radius: 1.875rem;
  // height: 100%;
  // overflow-y: auto;
}
// .quote-content {
//   background-color: white;
//   padding: 1rem;
//   overflow-y: auto;
//   width: 100%;
//   height: 100%;
//   border-radius: 1.5rem 1.5rem 0px 0px;
// }
</style>
